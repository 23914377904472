<template>
    <!-- Main sidebar -->
    <div class="sidebar sidebar-light sidebar-main sidebar-fixed sidebar-expand-md">

        <!-- Sidebar mobile toggler -->
        <div class="sidebar-mobile-toggler text-center">
            <a href="#" class="sidebar-mobile-main-toggle">
                <i class="icon-arrow-left8"></i>
            </a>
            <span class="font-weight-semibold">Navigation</span>
            <a href="#" class="sidebar-mobile-expand">
                <i class="icon-screen-full"></i>
                <i class="icon-screen-normal"></i>
            </a>
        </div>
        <!-- /sidebar mobile toggler -->


        <!-- Sidebar content -->
        <div class="sidebar-content">

            <!-- Main navigation -->
            <div class="card card-sidebar-mobile">
                <ul class="nav nav-sidebar" data-nav-type="accordion">
                    <li :class="[(keyPlatform == undefined || keyPlatform == '') ? 'main-active' : '']">
                        <router-link to="/" class="nav-link">
                            <i class="icon-home4"></i><span style="font-weight: 500;">Dashboard</span>
                        </router-link>
                    </li>
                    <li class="nav-item nav-item-submenu">
                        <a href="javascript:void(0);" class="nav-link justify-content-start pl-3">
                            <i class="icon-grid6"></i><span>Apps</span>
                        </a>
                        <ul class="nav nav-group-sub">
                            <li :class="[keyPlatform == 'apps-list' ? 'main-active' : '']">
                                <router-link :to="{ name: 'apps-list' }" class="nav-link">
                                    <i class="icon-stack2"></i> <span>Apps</span>
                                </router-link>
                            </li>
                            <li :class="[keyPlatform == 'app-request' || keyPlatform == 'app-request-detail' ? 'main-active' : '']">
                                <router-link :to="{ name: 'app-request' }" class="nav-link">
                                    <i class="icon-stack2"></i> <span>App Requests</span>
                                </router-link>
                            </li>
                            <li :class="[keyPlatform == 'app-pushes' || keyPlatform == 'app-push-detail' ? 'main-active' : '']">
                                <router-link :to="{ name: 'app-pushes' }" class="nav-link">
                                    <i class="icon-stack2"></i> <span>App Pushes</span>
                                </router-link>
                            </li>
                            <li :class="[keyPlatform == 'app-platform-requests' || keyPlatform == 'app-platform-requests-detail' ? 'main-active' : '']">
                                <router-link :to="{ name: 'app-platform-requests' }" class="nav-link">
                                    <i class="icon-stack2"></i> <span>App Platform Requests</span>
                                </router-link>
                            </li>
                            <!-- <li>
                                <router-link :to="{ name: 'app-responses'}" class="nav-link">
                                    <i class="icon-stack2"></i> <span>App Responses</span>
                                </router-link>
                            </li> -->
                        </ul>
                    </li>

                    <li class="nav-item nav-item-submenu">
                        <a href="javascript:void(0);" class="nav-link justify-content-start pl-3">
                            <i class="icon-cart4"></i><span>Orders</span>
                        </a>
                        <ul class="nav nav-group-sub">
                            <li :class="[keyPlatform == 'orders-list' || keyPlatform == 'orders-view' || keyPlatform == 'orders-view-json' ? 'main-active' : '']">
                                <router-link :to="{ name: 'orders-list' }" class="nav-link">
                                    <i class="fa-solid fa-box-archive"></i> <span>Orders</span>
                                </router-link>
                            </li>
                            <li :class="[keyPlatform == 'orders-incoming-list' ? 'main-active' : '']">
                                <router-link :to="{name: 'orders-incoming-list'}" class="nav-link">
                                    <i class="fa-solid fa-boxes-packing"></i> <span style="margin-left: -3px;">Orders Incoming</span>
                                </router-link>
                            </li> 
                            <li :class="[keyPlatform == 'orders-match-status-list' ? 'main-active' : '']">
                                <router-link :to="{name: 'orders-match-status-list'}" class="nav-link">
                                    <i class="fa-solid fa-code-compare"></i> <span>Orders Match Status</span>
                                </router-link>
                            </li> 
                        </ul>
                    </li>
                    
                    <li :class="[keyPlatform == 'products-list' || keyPlatform == 'product-detail' ? 'main-active' : 'nav-item']">
                        <router-link :to="{name: 'products-list'}" class="nav-link justify-content-start pl-3">
                            <i class="icon-bag"></i> <span>Products</span>
                        </router-link>
                    </li>
                    <li :class="[keyPlatform == 'product-link' || keyPlatform == 'product-link-detail' ? 'main-active' : 'nav-item']">
                        <router-link :to="{name: 'product-link'}" class="nav-link justify-content-start pl-3">
                            <i class="fa-solid fa-link"></i> <span>Product Link</span>
                        </router-link>
                    </li>
                    <li :class="[keyPlatform == 'movements-list' || keyPlatform == 'movements-detail' ? 'main-active' : 'nav-item']">
                        <router-link :to="{name: 'movements-list'}" class="nav-link justify-content-start pl-3">
                            <i class="fa-solid fa-boxes-stacked"></i> <span>Movements</span>
                        </router-link>
                    </li>
                    <li :class="[keyPlatform == 'buyers-list' || keyPlatform == 'buyers-detail' ? 'main-active' : 'nav-item']">
                        <router-link :to="{name: 'buyers-list'}" class="nav-link justify-content-start pl-3">
                            <i class="icon-users2"></i> <span>Buyers</span>
                        </router-link>
                    </li>
                    <li :class="[keyPlatform == 'shops-list' || keyPlatform == 'shop-detail' ? 'main-active' : 'nav-item']">
                        <router-link :to="{name: 'shops-list'}" class="nav-link justify-content-start pl-3">
                            <i class="icon-store"></i> <span>Shops</span>
                        </router-link>
                    </li>

                    <li :class="[keyPlatform == 'shipments-list' || keyPlatform == 'shipments-detail' ? 'main-active' : 'nav-item']">
                        <router-link :to="{name: 'shipments-list'}" class="nav-link justify-content-start pl-3">
                            <i class="icon-truck"></i> <span>Shipment</span>
                        </router-link>
                    </li>

                    <!-- Shiments Menu-->
                    <!-- <li class="nav-item nav-item-submenu">
                        <a href="javascript:void(0);" class="nav-link justify-content-start pl-3">
                            <i class="icon-grid6"></i><span>Shipment</span>
                        </a>
                        <ul class="nav nav-group-sub">
                            <li :class="[keyPlatform == 'shipments-list' ? 'main-active' : '']">
                                <router-link :to="{ name: 'shipments-list' }" class="nav-link">
                                    <i class="icon-paperplane"></i> <span>Shipment</span>
                                </router-link>
                            </li>
                            <li :class="[keyPlatform == 'shipments-log' ? 'main-active' : '']">
                                <router-link :to="{ name: 'shipments-log' }" class="nav-link">
                                    <i class="icon-newspaper"></i> <span>Shipment Log</span>
                                </router-link>
                            </li>
                        </ul>
                    </li> -->

                    <!-- <li :class="[keyPlatform == 'shipment-lables-list' || keyPlatform == 'shipment-labels-view' ? 'main-active' : 'nav-item']">
                        <router-link :to="{name: 'shipment-lables-list'}" class="nav-link justify-content-start pl-3">
                            <i class="fa-solid fa-file-lines pl-1"></i> <span>Shipment Labels</span>
                        </router-link>
                    </li> -->
                    
                    <li class="nav-item nav-item nav-item-submenu">
                        <a href="javascript:void(0);" class="nav-link justify-content-start pl-3">
                            <i class="icon-statistics"></i><span>Platform requests</span>
                        </a>
                        <ul class="nav nav-group-sub">
                            <li :class="[keyPlatform == 'shopee' ? 'sub-active': '']">
                                <router-link :to="{ path: '/platform_requests?platforms=shopee' }" class="nav-link">
                                <!-- <router-link :to="{ name: 'platform-view', params: { code: 'shopee' } }" class="nav-link"> -->
                                    <i class="icon-stack2"></i> <span>Shopee</span>
                                </router-link>
                            </li>
                            <li :class="[keyPlatform == 'tiktok_shop' ? 'sub-active': '']">
                                <router-link :to="{ path: '/platform_requests?platforms=tiktok_shop' }" class="nav-link">
                                <!-- <router-link :to="{ name: 'platform-view', params: { code: 'tiktok_shop' } }" class="nav-link"> -->
                                    <i class="icon-stack2"></i> <span>Tiktok</span>
                                </router-link>
                            </li>
                            <li :class="[keyPlatform === 'lazada' ? 'sub-active': '']">
                                <router-link :to="{ path: '/platform_requests?platforms=lazada' }" class="nav-link">
                                    <i class="icon-stack2"></i> <span>Lazada</span>
                                </router-link>
                            </li>
                            <li :class="[keyPlatform === 'line_myshop' ? 'sub-active': '']">
                                <router-link :to="{ path: '/platform_requests?platforms=line_myshop' }" class="nav-link">
                                    <i class="icon-stack2"></i> <span>LINE MyShop</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <!-- /main -->
                </ul>
            </div>
            <!-- /main navigation -->

        </div>
        <!-- /sidebar content -->

    </div>
    <!-- /main sidebar -->
</template>

<script>
export default {
    name: 'LeftMenu',
    data() {
        return {
            keyPlatform: ''
        }
    },
    watch: {
        '$route.query': {
            handler: function(nval, oval) {
                this.keyPlatform = !nval.platforms ? this.$route.name : nval.platforms
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>
    body.admin-layout .sidebar .nav-item a.router-link-active {
        background-color: transparent!important;
    }
    body.admin-layout .sidebar .nav-item li.sub-active a.router-link-active {
        background-color: #dce9f8!important;
    }
    .main-active {
        background-color: #dce9f8!important;
    }
</style>
