import Vue from 'vue'
import Router from 'vue-router'
const title_suffix = "- Omni Center";
Vue.use(Router)

let router = new Router({
    mode: "history",
    routes: [
        {
            path: '/health',
            component: () => import('@/components/pages/health.vue'),
            name: 'health',
            meta: { title: 'Health', suffix: title_suffix, name: 'Health', link: 'health' }
        },
        /** ------------------- Dashboard ----------------------------- */
        {
            path: '/',
            component: () => import('@/components/pages/dashboard/Dashboard.vue'),
            meta: {
                title: 'Dashboard',
                layout: 'admin-layout',
                suffix: title_suffix
            }
        },
        /** ------------------- Router Apps ----------------------------- */
        {
            path: '/apps',
            name: 'apps-list',
            component:() => import('@/components/pages/apps/list/Index.vue'),
            meta: {
                title: 'App list',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'App List'},
                ],
            }
        },
        {
            path: '/app_requests',
            name: 'app-request',
            component:() => import('@/components/pages/apps/request/Index.vue'),
            meta: {
                title: 'App Requests',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'App Requests'},
                ],
            }
        },
        {
            path: '/app_request/:id',
            name: 'app-request-detail',
            component: () => import('@/components/pages/apps/request/View.vue'),
            meta: {
                title: 'App Request detail',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'App Requests', link: '/app_requests' },
                    { name: 'App Request detail' }
                ],
            }
        },
        {
            path: '/app_pushes',
            name: 'app-pushes',
            component:() => import('@/components/pages/apps/pushes/Index.vue'),
            meta: {
                title: 'App Pushes',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'App Pushes', link: '/app_pushes'},
                ],
            }
        },
        {
            path: '/app_push/:id',
            name: 'app-push-detail',
            component:() => import('@/components/pages/apps/pushes/View.vue'),
            meta: {
                title: 'App Push detail',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'App Pushes', link: '/app_pushes' },
                    { name: 'App Pushes detail' }
                ],
            }
        },
        {
            path: '/app_platform_requests',
            name: 'app-platform-requests',
            component:() => import('@/components/pages/apps/platform_requests/Index.vue'),
            meta: {
                title: 'App Platform Requests',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'App Platform Requests', link: '/app_platform_requests'},
                ],
            }
        },
        {
            path: '/app_platform_requests/:id',
            name: 'app-platform-requests-detail',
            component:() => import('@/components/pages/apps/platform_requests/View.vue'),
            meta: {
                title: 'App Platform Requests detail',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'App Platform Requests', link: '/app_platform_requests' },
                    { name: 'App Platform requests detail' }
                ],
            }
        },
        /** ------------------- Router Orders ----------------------------- */
        {
            path: '/orders',
            component: () => import('@/components/pages/orders/Index.vue'),
            name: 'orders-list',
            meta: {
                title: 'Orders',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Orders', link: '/orders' },
                ],
            },
        },
        {
            path: '/orders/:id',
            component: () => import('@/components/pages/orders/View.vue'),
            name: 'orders-view',
            meta: {
                title: 'Order View',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Orders', link: '/orders' },
                    { name: 'Order detail'}
                ],
            }
        },
        {
            path: '/orders/detail/:order/:shop',
            component: () => import('@/components/pages/orders/ViewJson.vue'),
            name: 'orders-view-json',
            meta: {
                title: 'Order View Detail',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Orders', link: '/orders/detail' },
                    { name: 'Json data'}
                ],
            }
        },
        /** ------------------- Router Orders Incoming ----------------------------- */
        {
            path: '/orders-incoming',
            component: () => import('@/components/pages/orders_incoming/Index.vue'),
            name: 'orders-incoming-list',
            meta: {
                title: 'Orders Incoming',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Orders Incoming', link: '/orders-incoming' },
                ],
            },
        },
        /** ------------------- Router Orders Match Status ----------------------------- */
        {
            path: '/orders-match-status',
            component: () => import('@/components/pages/orders_match_status/Index.vue'),
            name: 'orders-match-status-list',
            meta: {
                title: 'Orders Match Status',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Orders Match Status', link: '/orders-match-status' },
                ],
            },
        },
        /** ------------------- Router Products ----------------------------- */
        {
            path: '/products',
            component: () => import('@/components/pages/products/Index.vue'),
            name: 'products-list',
            meta: {
                title: 'Products',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Products'},
                ],
            }
        },
        {
            path: '/product/:id',
            component: () => import('@/components/pages/products/View.vue'),
            name: 'product-detail',
            meta: {
                title: 'Product log',
                layout: 'admin-layout',
                breadcrumb: [
                    { name: 'Products', link: '/products'},
                    { name: 'Product detail' }
                ],
                suffix: title_suffix,
            }
        },
        /** ------------------- Router For Buyers ----------------------------- */
        {
            path: '/buyers',
            component: () => import('@/components/pages/buyers/Index.vue'),
            name: 'buyers-list',
            meta: {
                title: 'Buyers',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Buyers'},
                ],
            }
        },
        {
            path: '/buyers/:id',
            component: () => import('@/components/pages/buyers/View.vue'),
            name: 'buyers-detail',
            meta: {
                title: 'Buyer detail',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Buyers', link: '/buyers'},
                    { name: 'Buyer detail' }
                ],
            }
        },
        /** ------------------- Router For Shops ---------------------------- */
        {
            path: '/shops',
            component: () => import('@/components/pages/shops/Index.vue'),
            name: 'shops-list',
            meta: {
                title: 'Shops',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Shops'},
                ],
            }
        },
        {
            path: '/shops/:id',
            component: () => import('@/components/pages/shops/View.vue'),
            name: 'shop-detail',
            meta: {
                title: 'Shop log',
                layout: 'admin-layout',
                breadcrumb: [
                    { name: 'Shops', link: '/shops'},
                    { name: 'Detail' }
                ],
                suffix: title_suffix,
            }
        },
        {
            path: '/products/platform/:shop/:product',
            component: () => import('@/components/pages/shops/productseller/ViewJson.vue'),
            name: 'productseller-view-json',
            meta: {
                title: 'Product Seller View Detail',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Shops', link: '/shops/product' },
                    { name: 'Json data'}
                ],
            }
        },
        /** ------------------- Router For Shipment -------------------------- */
        {
            path: '/shipments',
            component: () => import('@/components/pages/shipments/Index.vue'),
            name: 'shipments-list',
            meta: {
                title: 'Shipments',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Shipment'},
                ],
            }
        },
        {
            path: '/shipments/:id',
            component: () => import('@/components/pages/shipments/View.vue'),
            name: 'shipments-detail',
            meta: {
                title: 'Shipments log',
                layout: 'admin-layout',
                breadcrumb: [
                    { name: 'Shipments', link: '/shipments'},
                    { name: 'Detail' }
                ],
                suffix: title_suffix,
            }
        },
        /** ------------------- Router For Shipment Logs -------------------------- */
        {
            path: '/shipments_log',
            name: 'shipments-log',
            component:() => import('@/components/pages/shipments_log/Index.vue'),
            meta: {
                title: 'Shipments Log',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Shipments Log', link: '/shipments_log'},
                ],
            }
        },
        // {
        //     path: '/shipments_log',
        //     component: () => import('@/components/pages/shipments_log/Index.vue'),
        //     name: 'shipments-log',
        //     meta: {
        //         title: 'Shipments Log',
        //         layout: 'admin-layout',
        //         suffix: title_suffix,
        //         breadcrumb: [
        //             { name: 'Shipment-log'},
        //         ],
        //     }
        // },
        /** ------------------- Router Shipment Labels ----------------------------- */
        {
            path: '/shipment_labels',
            component: () => import('@/components/pages/shipment_labels/Index.vue'),
            name: 'shipment-lables-list',
            meta: {
                title: 'Shipment Labels',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Shipment labels', link: '/shipment_labels' },
                ],
            },
        },
        {
            path: '/shipment_labels/:id',
            component: () => import('@/components/pages/shipment_labels/View.vue'),
            name: 'shipment-labels-view',
            meta: {
                title: 'Shipment Labels View',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Shipment Labels', link: '/shipment_labels' },
                    { name: 'Shipment Labels detail'}
                ],
            }
        },
        /** ------------------- Router For Webhook ----------------------------- */
        {
            path: '/platform_requests',
            component: () => import('@/components/pages/platform_requests/Index.vue'),
            meta: {
                title: 'Platforms request',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Platforms Request' }
                ],
            }
        },
        /** ------------------- Router Movements ----------------------------- */
        {
            path: '/movements',
            component: () => import('@/components/pages/movements/Index.vue'),
            name: 'movements-list',
            meta: {
                title: 'Movements',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Movements'},
                ],
            }
        },
        /** ------------------- Router Product Link ----------------------------- */
        {
            path: '/product-link',
            component: () => import('@/components/pages/product_link/Index.vue'),
            name: 'product-link',
            meta: {
                title: 'Product Link',
                layout: 'admin-layout',
                suffix: title_suffix,
                breadcrumb: [
                    { name: 'Product Link'},
                ],
            }
        },
        {
            path: '/product-link/:id',
            component: () => import('@/components/pages/product_link/View.vue'),
            name: 'product-link-detail',
            meta: {
                title: 'Product Link Detail',
                layout: 'admin-layout',
                breadcrumb: [
                    { name: 'Product link', link: '/product-link'},
                    { name: 'Product Link Detail' }
                ],
                suffix: title_suffix,
            }
        },
        /** ------------------- Login / Catch 404 ----------------------------- */
        {
            path: '/login',
            component: () => import('@/components/pages/auth/Login.vue'),
            name: 'login',
            meta: {
                title: "Login",
                layout: "authorization-layout",
                suffix: title_suffix
            },
        },
        {
            path: "/:catchAll(.*)",
            name: "Not Found",
            component: () => import("@/components/pages/404.vue"),
            meta: {
                title: "Not found",
                icon: "",
                breadcrumb: [{ name: "Not Found" }],
            },
        },
    ]
})

export default router